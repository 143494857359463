import React from 'react';
import '../index.css';
import img from '../images/telemarketing.svg'; 
import img2 from '../images/sms.svg';
import img3 from '../images/email.svg';
import img4 from '../images/search.svg';
import img5 from '../images/supply.svg';
import img6 from '../images/assess.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-purple-900 font-bold">Why We Are More Cost-Effective and Efficient Than Facebook and Google Ads?</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-purple-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-purple-900">When it comes to filling your schedule with ideal clients, we offer a solution that outperforms traditional platforms like Facebook and Google Ads in terms of cost, efficiency, and guaranteed results.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Guaranteed Results</h2>
                                    <p className="text-md font-medium"> 
                                    Unlike <b>Facebook</b> and <b>Google Ads</b>, where you pay for clicks or impressions without any guarantee of conversions, we provide <b>confirmed appointments</b> with clients actively seeking your services. Every lead is <b>exclusive, validated, and tailored</b> to maximize your return on investment.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Significant Cost Savings</h2>
                                    <p className="text-md font-medium">
                                    No need for inflated budgets to compete in ad auctions or pay for campaigns that may <b>not</b> deliver results. With us, you know <b>exactly</b> what you're investing weekly and what you can expect in return.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">No Middlemen, No Stress</h2>
                                    <p className="text-md font-medium">
                                    Forget about learning complicated ad platforms or hiring teams to manage them. We handle <b>everything</b>—from initial outreach to confirming appointments—so you can focus entirely on <b>closing deals</b>.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-purple-900">Industry-Focused Expertise</h2>
                                    <p className="text-md font-medium">
                                    While Facebook and Google Ads use algorithms to guess your audience, we directly <b>connect</b> your business with <b>real homeowners</b> actively interested in your services. This direct, personalized approach ensures higher conversions and less wasted effort.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <img src={img5} alt="" className="img-style" />
                            </div>
                            <h3 className="text-3xl  text-purple-900 
                            font-bold">We Deliver <span className='font-black'>Certainty</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                At CommunityTech, we don’t just supply leads; we deliver guaranteed, exclusive appointments tailored to your business needs. Unlike traditional advertising platforms, our approach ensures you’re not just getting clicks or inquiries—you’re getting actual homeowners ready to engage with your services. Every lead is validated, saving you time and maximizing your efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                
                                <img src= {img6} alt="" className="img-style"/>
                            </div>
                            <h3 className="text-3xl  text-purple-900 font-bold">We Provide <span className='font-black'>Transparency</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Forget the guesswork of ad performance metrics or hidden costs. With CommunityTech, you know exactly what you’re paying for and what to expect in return. Our clients receive a predictable stream of exclusive leads without the overhead of managing ad campaigns or hiring additional marketing teams.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;